<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">福袋管理</h3>
    </div>
    <div class="container" ref="container">
      <div class="top" style="margin-bottom: 20px">
        <el-form ref="filterForm" v-model="filterForm" inline>
          <el-form-item label="是否展示：">
            <el-select v-model="filterForm.status" clearable placeholder="请选择">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="福袋名称：">
            <el-input v-model="filterForm.title" clearable placeholder="请输入福袋名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="serchTable">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-button icon="el-icon-plus" @click="$router.push('/live/addLuckyBag')">添加福袋</el-button>
        <el-button icon="el-icon-delete" type="danger" @click="deleteBag">批量删除福袋</el-button>
      </div>
      <div class="content">
        <el-table
          :data="tableData"
          border
          :max-height="containerHeight - 250"
          stripe
          @selection-change="selectRow"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <template v-for="(item, index) in tableColumn">
            <el-table-column
              v-if="item.prop=='is_real'"
              :key="'a'+index"
              :width="item.width"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.is_real==1">真实用户</template>
                <template v-else-if="scope.row.is_real==2">虚拟用户</template>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop=='comment'"
              :key="'a'+index"
              :width="item.width"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                评论“{{ scope.row.comment }}”
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop=='status'"
              :key="'a'+index"
              :width="item.width"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.status == 2">
                  <template v-if="!scope.row.endtime">
                    <el-switch
                        v-model="scope.row.status"
                        @click.native="switchChange(scope.row, scope.$index)"
                        :active-value="1"
                        :inactive-value="2"
                        :disabled='true'
                    />
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'createtime'"
              :key="'a'+index"
              :width="item.width"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.createtime ? filterTime(scope.row.createtime) : '' }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'endtime'"
              :key="'a'+index"
              :width="item.width"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.endtime ? filterTime(scope.row.endtime): '' }}
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="'a'+index"
              :width="item.width"
              :prop="item.prop"
              :label="item.label"
              align="center"
            />
          </template>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <div class="option">
                <template v-if="scope.row.status == 2">
                  <template v-if="!scope.row.endtime">
                    <a href="javascript:;" style="color: #409EFF" @click="addLuckyBag(scope.row)">编辑</a>
                  </template>
                  <template v-else>
                    <a href="javascript:;" style="color: #409EFF" @click="openWinner(scope.row)">查看中奖名单</a>
                  </template>
                </template>
                <a href="javascript:;" style="color: #f78989" @click="deleteBag(scope.row)">删除</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="SetPage"
        :current-page="filterForm.page"
        :page-size="filterForm.limit"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="上架福袋"
      :visible.sync="upLuckyBagLayer"
      width="30%"
      :before-close="closeLayer"
    >
      <el-input
        v-model.number="countdown"
        placeholder="请输入时间"
      >
        <template slot="prepend">开奖倒计时：</template>
        <span slot="append">分钟</span>
      </el-input>

      <span slot="footer" class="dialog-footer">
      <el-button @click="upLuckyBagLayer = false">取 消</el-button>
      <el-button type="primary" @click="changeStatus">确 定</el-button>
    </span>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getListData,
  changeBagStatus,
  delBag
} from "@/api/luckyBag";

export default {
  name: "luckyBag",
  data() {
    return {
      showErr: false,
      // 上架福袋时间
      countdown:'',
      // 上架福袋弹窗
      upLuckyBagLayer: false,
      // 容器宽高
      containerHeight: null,
      containerWidth: null,
      // 筛选表单
      filterForm: {
        limit: 10,
        page: 1
      },
      total: 20,
      // 表格数据
      tableData: [],
      // 表头数据
      tableColumn: [
        {
          label: '福袋名称',
          prop: 'title',
          width: 200,
          fixed: 'left',
        },
        {
          label: '福袋奖品',
          prop: 'goods_name',
          width: 250,
          fixed: 'left',
        },
        {
          label: '中奖数量',
          prop: 'number',
          width: 70,
          fixed: false,
        },
        {
          label: '是否虚拟用户中奖',
          prop: 'is_real',
          width: 80,
          fixed: false,
        },
        {
          label: '开奖时间',
          prop: 'endtime',
          width: 180,
          fixed: false,
        },
        {
          label: '添加时间',
          prop: 'createtime',
          width: 180,
          fixed: false,
        },
        {
          label: '添加人',
          prop: 'admin_name',
          width: 120,
          fixed: false,
        },
        {
          label: '参与条件',
          prop: 'comment',
          width: 180,
          fixed: 'right',
        },
        {
          label: '是否展示',
          prop: 'status',
          width: 80,
          fixed: 'right',
        },
      ],
      // 被选中行的id
      ids: [],
      // 被选中的当前行
      curRow: {}
    }
  },
  methods: {
    /**
     * 搜索表格数据
     */
    serchTable() {
      this.filterForm.page = 1
      this.getList()
    },
    /**
     * 分页器
     * @param e
     * @constructor
     */
    SetPage(e) {
      this.filterForm.page = e
      this.getList()
    },
    /**
     * 选择行
     * @param row   行数据
     */
    selectRow(row) {
      let ids = []
      if (row.length > 0) {
        row.forEach((item) => {
          ids.push(item.id)
        })
      }
      this.ids = [...ids]
    },
    /**
     * 添加/编辑福袋
     * @param row 当前选中行
     */
    addLuckyBag(row) {
      this.$router.push({
        path: "/live/addLuckyBag",
        query: { id: row.id },
      });
    },
    /**
     * 查看中奖名单
     * @param row 当前选中行
     */
    openWinner(row) {
      this.$router.push({
        path: "/live/luckyBagInfo",
        query: { id: row.id },
      });
    },
    /**
     * 获取页面数据
     */
    async getList() {
      let { data } = await getListData(this.filterForm)
      this.total = data.data.total
      this.tableData = [...data.data.data]
    },
    /**
     * 删除福袋
     * @param row   当前被选中行数据
     */
    deleteBag(row) {
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      } else {
        if (this.ids.length <= 0) {
          this.$message.error('请选择要分配的工单')
          return
        }
      }
      this.$confirm('此操作将永久删除福袋, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delBag({id:this.ids}).then(res=>{
          if (res.data.code==200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        })

      }).catch(()=>{
        console.log('取消删除');
      });
    },
    /**
     * 是否展示开关
     * @param row 当前行数据
     * @param index 当前行下标
     */
    async switchChange(row, index) {
      this.curRow = index
      if (row.status==2) {
        this.upLuckyBagLayer = true
      }
      // else {
      //   let datas = {}
      //   datas.id = row.id
      //   datas.status = 2
      //   datas.countdown = ''
      //   let data = await changeBagStatus(datas)
      //   console.log(data);
      // }
    },
    /**
     * 切换是否展示状态
     */
    async changeStatus() {
      if (this.countdown) {
        let datas = {}
        if (this.tableData[this.curRow].status == 2){
          datas.status = 1
        } else {
          datas.status = 2
        }
        datas.countdown = this.countdown
        datas.id = this.tableData[this.curRow].id
        let data = await changeBagStatus(datas)
        this.getList()
        // this.tableData[this.curRow].status = !this.tableData[this.curRow].status
        this.closeLayer()
      } else {
        this.$message.error('倒计时不能为空')
      }
    },
    /**
     * 关闭弹窗
     */
    closeLayer() {
      this.countdown = ''
      this.upLuckyBagLayer = false
    },
    /**
     * 格式化时间
     * @param stamp       时间戳
     * @returns {string}  格式化完成的时间字符串
     */
    filterTime(stamp){
      let date = new Date(stamp*1000).toLocaleString('chinese',{hour12:false}).replace(/\//g, "-")
      return date
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.containerHeight = this.$refs['container'].offsetHeight
    console.log(this.containerHeight);
    this.containerWidth = this.$refs['container'].offsetWidth
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="less">
/deep/.el-card__body {
  height: 100%;
}
.container {
  padding: 20px;
  height: 100%;
  .option {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
