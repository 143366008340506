import request from "./http.js";

// 获取福袋列表
export const getListData = (data) => {
  return request({
    url: "/backend/blessing_bag/blessingBagList",
    method: "get",
    params: data,
  });
};
// 添加/修改福袋内容
export const addBag = (data) => {
  return request({
    url: "/backend/blessing_bag/saveBlessingBag",
    method: "post",
    data,
  });
};
// 删除福袋
export const delBag = (data) => {
  return request({
    url: "/backend/blessing_bag/delBlessingBag",
    method: "post",
    data,
  });
};
// 获取福袋详情
export const getBagData = (data) => {
  return request({
    url: "/backend/blessing_bag/blessingBagDetail",
    method: "get",
    params: data,
  });
};
// 修改福袋状态
export const changeBagStatus = (data) => {
  return request({
    url: "/backend/blessing_bag/statusBlessingBag",
    method: "post",
    data,
  });
};
// 获取直播间列表
export const getLiveListData = (data) => {
  return request({
    url: "/backend/blessing_bag/getLive",
    method: "get",
    params: data,
  });
};
// 获取商品列表
export const getGoodsListData = (data) => {
  return request({
    url: "/backend/blessing_bag/getGoods",
    method: "get",
    params: data,
  });
};

// 获取所有福袋中奖人列表
export const getAllBagWinnerList = (data, type) => {
  if(type){
    return request({
      url: '/backend/blessing_bag/blessingBagLogList',
      method: 'post',
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      data
    })
  }else{
    return request({
      url: '/backend/blessing_bag/blessingBagLogList',
      method: 'post',
      data
    })
  }
};

